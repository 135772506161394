import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveCategoryType,
  getPhaseCategoryHome,
  getGroupCategoryHome,
  getMultipleProducts,
  getMultipleProductsCount,
  loadMoreMultipleProducts,
} from './thunks';
import { isWeb } from '~global/screens';
export const { actions, reducer } = createSlice({
  name: 'newLillaCategory',
  initialState: {
    activeCategoryType: {},
    phaseCategoryHome: [],
    groupCategoryHome: [],
    getMultipleProducts: [],
    getMultipleProductsCount: 0,
    listGroupCategoriesByPhase: [],
    status: {
      activeCategoryType: 'idle',
      phaseCategoryHome: 'idle',
      groupCategoryHome: 'idle',
      getMultipleProducts: 'idle',
      getMultipleProductsCount: 'idle',
      loadMoreMultipleProducts: 'idle',
    },
  },
  reducers: {
    resetGetMultipleProducts: (state) => {
      state.status.getMultipleProducts = 'idle';
      state.status.getMultipleProductsCount = 'idle';
      state.status.loadMoreMultipleProducts = 'idle';
      state.getMultipleProductsCount = 0;
      state.getMultipleProducts = [];
    },
    SET_LIST_GROUP_CATEGORY_BY_PHASE(state, { data }) {
      state.listGroupCategoriesByPhase = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCategoryType.pending, (state) => {
        state.status.activeCategoryType = 'loading';
      })
      .addCase(getActiveCategoryType.rejected, (state) => {
        state.status.activeCategoryType = 'error';
      })
      .addCase(getActiveCategoryType.fulfilled, (state, { payload }) => {
        state.activeCategoryType = payload?.data[0] ?? {};
        state.status.activeCategoryType = 'finished';
      })
      .addCase(getPhaseCategoryHome.pending, (state) => {
        state.status.phaseCategoryHome = 'loading';
      })
      .addCase(getPhaseCategoryHome.rejected, (state) => {
        state.status.phaseCategoryHome = 'error';
      })
      .addCase(getPhaseCategoryHome.fulfilled, (state, { payload }) => {
        state.phaseCategoryHome = payload.data;
        state.status.phaseCategoryHome = 'finished';
      })
      .addCase(getGroupCategoryHome.pending, (state) => {
        state.status.groupCategoryHome = 'loading';
      })
      .addCase(getGroupCategoryHome.rejected, (state) => {
        state.status.groupCategoryHome = 'error';
      })
      .addCase(getGroupCategoryHome.fulfilled, (state, { payload }) => {
        state.groupCategoryHome = payload.data;
        state.status.groupCategoryHome = 'finished';
      })
      .addCase(getMultipleProducts.pending, (state) => {
        state.status.getMultipleProducts = 'loading';
      })
      .addCase(getMultipleProducts.rejected, (state) => {
        state.status.getMultipleProducts = 'error';
      })
      .addCase(getMultipleProducts.fulfilled, (state, { payload }) => {
        state.getMultipleProducts = payload.data;
        state.status.getMultipleProducts = 'finished';
      })
      .addCase(getMultipleProductsCount.pending, (state) => {
        state.status.getMultipleProductsCount = 'loading';
      })
      .addCase(getMultipleProductsCount.rejected, (state) => {
        state.status.getMultipleProductsCount = 'error';
      })
      .addCase(getMultipleProductsCount.fulfilled, (state, { payload }) => {
        state.getMultipleProductsCount = payload.data;
        state.status.getMultipleProductsCount = 'finished';
      })
      .addCase(loadMoreMultipleProducts.pending, (state) => {
        state.status.loadMoreMultipleProducts = 'loading';
      })
      .addCase(loadMoreMultipleProducts.rejected, (state) => {
        state.status.loadMoreMultipleProducts = 'error';
      })
      .addCase(loadMoreMultipleProducts.fulfilled, (state, { payload }) => {
        state.status.loadMoreMultipleProducts = 'finished';
        if (!isWeb) {
          const existingIds = state.getMultipleProducts.map(
            (product) => product.id,
          );
          const uniqueNewProducts = payload.data.filter(
            (product) => !existingIds.includes(product.id),
          );

          state.getMultipleProducts = [
            ...state.getMultipleProducts,
            ...uniqueNewProducts,
          ];
        } else {
          state.getMultipleProducts = payload.data;
        }
      });
  },
});

export const { resetGetMultipleProducts, SET_LIST_GROUP_CATEGORY_BY_PHASE } =
  actions;
export default reducer;

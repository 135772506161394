import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import { getChildAge } from '~global/helpers';

export const getProducts = createAsyncThunk(
  'home/getProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

export const getPhaseTag = createAsyncThunk(
  'home/getPhaseTag',
  async ({ profile, isLoggedIn }) => {
    try {
      // define phase tag
      const hasTracker = profile.current_mother_status !== 'no';
      const tagFornotLoginAndNoTracker = ['Categories'];
      const tagForloginAndTracker = [
        'Pregnancy',
        'Breastfeeding',
        'Newborn',
        'MPASI',
        'Lil`One',
      ];
      const response = await Catalog.tagsRequest({
        filter: {
          level_name: {
            $in:
              isLoggedIn && hasTracker
                ? tagForloginAndTracker
                : tagFornotLoginAndNoTracker,
          },
          is_phase: true,
          is_active_for_lulla: true,
        },
        fields: 'name code level_name is_phase position campaign_slug',
        limit: 0,
        sort: 'position',
      });

      if (
        response?.data?.data &&
        Array.isArray(response.data.data) &&
        response.data.data.length
      ) {
        response.data.data.forEach((tag, key) => {
          if (isLoggedIn && hasTracker) {
            /*
              pregnancy: pregnant mom
              breastfeeding: 0-3 bulan
              Newborn: 3-5 bulan
              MPASI: >6 bulan
              Lil`One Has Children >=1 years
            */
            if (
              profile.phase_of_mother === 'expecting' &&
              tag.level_name === 'Pregnancy'
            ) {
              // pregnant
              response.data.default = response.data.data[key];
              response.data.data[key].isActive = true;
            } else {
              // has child
              const youngestChild =
                profile.children &&
                Array.isArray(profile.children) &&
                profile.children.length
                  ? profile.children[0]
                  : null;

              const childAge = youngestChild?.date_of_birth
                ? getChildAge(youngestChild.date_of_birth)
                : null;

              if (
                (childAge?.month < 3 ||
                  (childAge?.month === 3 && childAge?.day === 0)) &&
                childAge?.year < 1
              ) {
                response.data.default = response.data.data[1];
                response.data.data[1].isActive = true;
              } else if (
                ((childAge?.month >= 3 && childAge?.month < 6) ||
                  (childAge?.month === 6 && childAge?.day === 0)) &&
                childAge?.year < 1
              ) {
                response.data.default = response.data.data[2];
                response.data.data[2].isActive = true;
              } else if (childAge?.month > 6 && childAge?.year < 1) {
                response.data.default = response.data.data[3];
                response.data.data[3].isActive = true;
              } else if (childAge?.year >= 1) {
                response.data.default = response.data.data[4];
                response.data.data[4].isActive = true;
              }
            }
          } else if (!isLoggedIn || !hasTracker) {
            response.data.default = response.data.data[0];
            response.data.data[0].isActive = true;
          } else {
            response.data.data[key].isActive = false;
          }
        });

        if (!response.data.default) {
          response.data.data[0].isActive = true;
          response.data.default = response.data.data[0];
        }
      }

      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCategoriesByPhase = createAsyncThunk(
  'home/getCategoriesByPhase',
  async (params) => {
    const response = await Catalog.categoriesRequest(params);
    response.data.phaseId = params.filter['phase_tags.id'];
    if (response?.data?.data && Array.isArray(response.data.data)) {
      response.data.data.forEach((category, key) => {
        // filter phase tag only return match with phaseid
        response.data.data[key].phase_tags = category.phase_tags.filter(
          (phase) => phase.id === params.filter['phase_tags.id'],
        );
      });
    }
    return response.data;
  },
);

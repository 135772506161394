import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
const $size = require('lodash.size');

export const getActiveCategoryType = createAsyncThunk(
  'newLillaCategory/getActiveCategoryType',
  async () => {
    const response = await Catalog.activeCategoryType();
    return response.data;
  },
);

export const getPhaseCategoryHome = createAsyncThunk(
  'newLillaCategory/getPhaseCategoryHome',
  async () => {
    const response = await Catalog.getPhaseCategoryHome();
    return response.data;
  },
);

export const getGroupCategoryHome = createAsyncThunk(
  'newLillaCategory/getGroupCategoryHome',
  async () => {
    try {
      const response = await Catalog.getGroupCategoryHome();
      const positionMapping = {
        Pregnancy: 'position_pregnancy',
        Breastfeeding: 'position_breastfeeding',
        Newborn: 'position_newborn',
        MPASI: 'position_mpasi',
        'Lil Ones': 'position_lil_ones',
        Categories: 'position_categories',
      };

      if ($size(response.data.data)) {
        response.data.data.forEach((data, keyData) => {
          if ($size(data.phase_tags)) {
            data.phase_tags.forEach((tag) => {
              const positionKey = positionMapping[tag?.name];
              if (positionKey) {
                response.data.data[keyData][positionKey] = Number(
                  tag.position_head_on_home,
                );
              }
            });
          }

          if ($size(data.group_child)) {
            data.group_child.forEach((child, keyChild) => {
              if ($size(child.phase_tags)) {
                child.phase_tags.forEach((tag) => {
                  const positionKey = positionMapping[tag?.name];
                  if (positionKey) {
                    response.data.data[keyData].group_child[keyChild][
                      positionKey
                    ] = Number(tag.position_on_home);
                  }
                });
              }

              // add _id parent to group_child
              if (data._id) {
                response.data.data[keyData].group_child[keyChild].id_parent =
                  data._id;
              }
            });

            // Check if parent data is already in group_child
            const parentExists = data.group_child.some(
              (child) => child._id === data._id,
            );
            if (!parentExists) {
              const remapParentData = {
                ...data,
                name: data.group_name,
                group_slug: data.slug,
                group_child_position: data.group_position,
              };
              const { group_child, ...parentData } = remapParentData;
              parentData.is_parent = true;

              // Add parent data to group_child
              data.group_child.push(parentData);
            }
          }
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getMultipleProducts = createAsyncThunk(
  'newLillaCategory/getMultipleProducts',
  async ({
    lillaCategoryType,
    slug,
    params,
    type,
    active_cat_type,
    phase_category,
  }) => {
    const filter = {
      lillaCategoryType,
      params,
      type,
      slug,
      active_cat_type,
      phase_category,
    };
    const response = await Catalog.getMultipleProducts(filter);
    return response.data;
  },
);

export const getMultipleProductsCount = createAsyncThunk(
  'newLillaCategory/getMultipleProductsCount',
  async ({
    lillaCategoryType,
    slug,
    params,
    type,
    active_cat_type,
    phase_category,
  }) => {
    const response = await Catalog.getMultipleProductsCount({
      lillaCategoryType,
      params,
      type,
      slug,
      active_cat_type,
      phase_category,
    });
    return response.data;
  },
);

export const loadMoreMultipleProducts = createAsyncThunk(
  'newLillaCategory/loadMoreMultipleProducts',
  async ({
    lillaCategoryType,
    slug,
    params,
    type,
    active_cat_type,
    phase_category,
  }) => {
    const response = await Catalog.getMultipleProducts({
      lillaCategoryType,
      slug,
      params,
      type,
      active_cat_type,
      phase_category,
    });
    return response.data;
  },
);

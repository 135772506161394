import { createAsyncThunk } from '@reduxjs/toolkit';
import { Soco } from '~api/soco';
import { Catalog } from '~api/catalog';
export const searchProductRequest = createAsyncThunk(
  'createReview/searchProductRequest',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Catalog.searchV3Products(params);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const addReview = createAsyncThunk(
  'createReview/addReview',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Soco.addReview(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const getReviews = createAsyncThunk(
  'createReview/getReviews',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Soco.getReviews(params);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const getProductDetail = createAsyncThunk(
  'createReview/getProductDetail',
  async (productId) => {
    const response = await Soco.getProductDetail(productId);
    return response.data;
  },
);

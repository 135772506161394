import { createSlice } from '@reduxjs/toolkit';
import Brands from '@web-components/headers/partial/Brands';
import DropdownCategories from '@web-components/headers/DropdownCategories';
import { getProducts, getPhaseTag, getCategoriesByPhase } from './thunks';

const dropdowns = [
  {
    name: 'Categories',
    key: 'categories',
    show: false,
    component: DropdownCategories,
  },
  {
    name: 'Brands',
    key: 'brands',
    show: false,
    component: Brands,
  },
];
export const { actions, reducer } = createSlice({
  name: 'home',
  initialState: {
    topBanners: [],
    contentOffsetY: 0,
    dropdowns,
    listEgift: [],
    phaseTags: [],
    categoriesPhase: {},
    activePhaseCategory: '',
    selectedPhaseTag: '',
    status: {
      products: 'idle',
      phaseTags: 'idle',
      categoriesPhase: 'idle',
    },
  },
  reducers: {
    setTopBanners(state, { data }) {
      state.topBanners = data;
    },
    setContentOffsetY(state, { data }) {
      state.contentOffsetY = data;
    },
    setDropdowns(state, { payload: [key, value, index] }) {
      state.dropdowns[index][key] = value;
    },
    resetDropdowns(state) {
      state.dropdowns = dropdowns;
    },
    setListEgift(state, { data }) {
      state.listEgift = data;
    },
    updatePhaseTag(state, { key }) {
      // reset all phaseTag isActive to false
      for (let i = 0; i < state.phaseTags.length; i++) {
        state.phaseTags[i].isActive = false;
      }

      state.phaseTags[key].isActive = true;
    },
    updateActivePhaseCategory(state, { phaseTag }) {
      state.activePhaseCategory = phaseTag;
      state.selectedPhaseTag = phaseTag;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status.products = 'loading';
      })
      .addCase(getProducts.rejected, (state) => {
        state.status.products = 'error';
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.listEgift = payload.data;
        state.status.products = 'finished';
      })
      .addCase(getPhaseTag.pending, (state) => {
        state.status.phaseTags = 'loading';
      })
      .addCase(getPhaseTag.rejected, (state) => {
        state.status.phaseTags = 'error';
      })
      .addCase(getPhaseTag.fulfilled, (state, { payload }) => {
        state.phaseTags = payload.data;
        state.activePhaseCategory = payload.default;
        state.status.phaseTags = 'finished';
      })
      .addCase(getCategoriesByPhase.pending, (state) => {
        state.status.categoriesPhase = 'loading';
      })
      .addCase(getCategoriesByPhase.rejected, (state) => {
        state.status.categoriesPhase = 'error';
      })
      .addCase(getCategoriesByPhase.fulfilled, (state, { payload }) => {
        state.categoriesPhase[payload.phaseId] = payload.data;
        state.status.categoriesPhase = 'finished';
      });
  },
});

export const { setDropdowns, resetDropdowns } = actions;

export default reducer;

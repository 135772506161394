import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import { isWeb } from '~global/screens';

const initialState = {
  products: [],
  totalProducts: '...',
  status: {
    products: 'idle',
    totalProducts: 'idle',
    loadMoreProducts: 'idle',
  },
};

export const getProducts = createAsyncThunk(
  'egiftList/getProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

export const getTotalProducts = createAsyncThunk(
  'egiftList/getTotalProducts',
  async (params) => {
    const response = await Catalog.getTotalProductsRequest(params);
    return response.data;
  },
);

export const loadMoreProducts = createAsyncThunk(
  'egiftList/loadMoreProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data;
  },
);

const egiftListSlice = createSlice({
  name: 'egiftList',
  initialState,
  reducers: {
    SET_STATE(state, { value, name }) {
      state[name] = value;
    },
    SET_STATUS_API(state, { value, name }) {
      state.status[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.status.products = 'loading';
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status.products = 'error';
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.products = payload.data;
        state.status.products = 'finished';
      })
      .addCase(getTotalProducts.pending, (state, action) => {
        state.status.totalProducts = 'loading';
      })
      .addCase(getTotalProducts.rejected, (state, action) => {
        state.status.totalProducts = 'error';
      })
      .addCase(getTotalProducts.fulfilled, (state, { payload }) => {
        state.totalProducts = payload.data;
        state.status.totalProducts = 'finished';
      })
      .addCase(loadMoreProducts.pending, (state, action) => {
        state.status.loadMoreProducts = 'loading';
      })
      .addCase(loadMoreProducts.rejected, (state, action) => {
        state.status.loadMoreProducts = 'error';
      })
      .addCase(loadMoreProducts.fulfilled, (state, { payload }) => {
        if (isWeb) {
          state.products = payload.data;
        } else {
          const existingIds = state.products.map((product) => product.id);
          const newUniqueProducts = payload.data.filter(
            (product) => !existingIds.includes(product.id),
          );
          state.products = [...state.products, ...newUniqueProducts];
        }

        state.status.loadMoreProducts = 'finished';
      });
  },
});

export const { SET_STATE, SET_STATUS_API } = egiftListSlice.actions;
export default egiftListSlice.reducer;

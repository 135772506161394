import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getFeaturedBrands,
  getBrandsLetters,
  getBrand,
  getTotalCategories,
  getTotalProducts,
  getVouchers,
  getBanners,
  getAllProducts,
  getBestSellerProducts,
  getWhatsNewProducts,
  getFeaturedBrandsV2,
} from '~api/brand';
import { isWeb } from '~global/screens';
import { Catalog } from '~api/catalog';
import { GoogleTagManager } from '~global/sociolla.dm.lib';
export const getBrandCategories = createAsyncThunk(
  'brand/getBrandCategories',
  async (params) => {
    const response = await Catalog.getBrandCategories(params);
    return response.data;
  },
);

export const getFeaturedBrandsRequest = createAsyncThunk(
  'brand/getFeaturedBrandsRequest',
  async () => {
    const response = await getFeaturedBrands();
    return response.data;
  },
);

export const getFeaturedBrandsRequestV2 = createAsyncThunk(
  'brand/v2/getFeaturedBrandsRequest',
  async (params) => {
    const response = await getFeaturedBrandsV2(params);
    return response.data;
  },
);

export const getBrandsLettersRequest = createAsyncThunk(
  'brand/getBrandsLettersRequest',
  async () => {
    const response = await getBrandsLetters();
    return response.data;
  },
);

export const getBrandRequest = createAsyncThunk(
  'brand/getBrandRequest',
  async (brandId) => {
    const response = await getBrand(brandId);
    if (isWeb) {
      GoogleTagManager.viewBrands(response.data.data);
    }
    return response.data;
  },
);

export const getTotalCategoriesRequest = createAsyncThunk(
  'brand/getTotalCategoriesRequest',
  async (brandId) => {
    const response = await getTotalCategories(brandId);
    return response.data;
  },
);

export const getVouchersRequest = createAsyncThunk(
  'brand/getVouchersRequest',
  async (brandId) => {
    const response = await getVouchers(brandId);
    return response.data;
  },
);

export const getBannersRequest = createAsyncThunk(
  'brand/getBannersRequest',
  async (brandId) => {
    const response = await getBanners(brandId);
    return response.data;
  },
);

export const getTotalProductsRequest = createAsyncThunk(
  'brand/getTotalProductsRequest',
  async ({ id, params }) => {
    const response = await getTotalProducts({ id, params });
    return response.data;
  },
);

export const getAllProductsRequest = createAsyncThunk(
  'brand/getAllProductsRequest',
  async ({ id, params }) => {
    const response = await getAllProducts({ id, params });
    return response.data;
  },
);

export const loadMoreAllProductsRequest = createAsyncThunk(
  'brand/loadMoreAllProductsRequest',
  async ({ id, params }) => {
    const response = await getAllProducts({ id, params });
    return response.data;
  },
);

export const getBestSellerProductsRequest = createAsyncThunk(
  'brand/getBestSellerProductsRequest',
  async (brandId) => {
    const response = await getBestSellerProducts(brandId);
    return response.data;
  },
);

export const getWhatsNewProductsRequest = createAsyncThunk(
  'brand/getWhatsNewProductsRequest',
  async (brandId) => {
    const response = await getWhatsNewProducts(brandId);
    return response.data;
  },
);

export const getBrandProductPromotion = createAsyncThunk(
  'brand/brandProductPromotion',
  async (params) => {
    try {
      const response = await Catalog.getBrandProductPromotion(params);
      response.data.data.map((promotion, indexPromotion) => {
        const sectionProducts = promotion.sections.find(
          (section) => section.type === 'product',
        );
        const sectionBanners = promotion.sections.find(
          (section) => section.type === 'banner',
        );

        if (sectionProducts && sectionProducts.product) {
          response.data.data[indexPromotion].products = sectionProducts.product;
        }

        if (sectionBanners && sectionBanners.product) {
          response.data.data[indexPromotion].banners = sectionBanners.banner;
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
);
const brandSlice = createSlice({
  name: 'BRAND',
  initialState: {
    featuredBrands: [],
    featuredBrandsV2: [],
    brandsLetters: [],
    brandsFiltered: [],
    detailInfo: {},
    totalCategories: '...',
    totalProducts: '...',
    vouchers: [],
    banners: [],
    allProducts: [],
    whatsNewProducts: [],
    bestSellerProducts: [],
    tabViewName: 'best_seller',
    brandCategories: [],
    productCategoryName: 'All Products',
    isFilterSticky: false,
    promotionSection: [],
    tabMenus: [
      {
        name: 'Home',
        isActived: false,
      },
      {
        name: 'Products',
        isActived: false,
      },
      {
        name: 'Promo',
        isActived: false,
      },
      {
        name: 'Highlight',
        isActived: false,
      },
    ],
    status: {
      brandCategories: 'idle',
      featuredBrands: 'idle',
      featuredBrandsV2: 'idle',
      brandsLetters: 'idle',
      detailInfo: 'idle',
      totalCategories: 'idle',
      totalProducts: 'idle',
      vouchers: 'idle',
      banners: 'idle',
      allProducts: 'idle',
      whatsNewProducts: 'idle',
      bestSellerProducts: 'idle',
      loadMoreAllProducts: 'idle',
      promotionSection: 'idle',
    },
  },
  reducers: {
    SET_TAB_MENUS(state, { index }) {
      state.tabMenus.map((menu, indexMenu) => {
        if (index === indexMenu) {
          state.tabMenus[indexMenu].isActived = true;
        } else {
          state.tabMenus[indexMenu].isActived = false;
        }
      });
    },
    SET_BRAND_STATE(state, { data, stateName }) {
      state[stateName] = data;
    },
    SET_STATUS_BRAND_API(state, { data, stateName }) {
      state.status[stateName] = data;
    },
    RESET_STATE_BRAND_DETAIL(state, data) {
      state.detailInfo = {};
      state.totalCategories = '...';
      state.totalProducts = '...';
      state.vouchers = [];
      state.banners = [];
      state.allProducts = [];
      state.whatsNewProducts = [];
      state.bestSellerProducts = [];
      state.tabViewName = 'best_seller';
      state.brandCategories = [];
      state.isFilterSticky = false;
      state.promotionSection = [];
      state.tabMenus.map((item, key) => {
        if (item.name === data.tab) {
          state.tabMenus[key].isActived = true;
        } else {
          state.tabMenus[key].isActived = false;
        }
      });
      state.status = Object.assign(state.status, {
        detailInfo: 'idle',
        totalCategories: 'idle',
        totalProducts: 'idle',
        vouchers: 'idle',
        banners: 'idle',
        allProducts: 'idle',
        whatsNewProducts: 'idle',
        bestSellerProducts: 'idle',
        loadMoreAllProducts: 'idle',
        brandCategories: 'idle',
        promotionSection: 'idle',
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeaturedBrandsRequest.pending, (state, action) => {
        state.status.featuredBrands = 'loading';
      })
      .addCase(getFeaturedBrandsRequest.rejected, (state, action) => {
        state.status.featuredBrands = 'error';
      })
      .addCase(getFeaturedBrandsRequest.fulfilled, (state, { payload }) => {
        state.featuredBrands = payload.data;
        state.status.featuredBrands = 'succeeded';
      })
      .addCase(getFeaturedBrandsRequestV2.pending, (state, action) => {
        state.status.featuredBrands = 'loading';
      })
      .addCase(getFeaturedBrandsRequestV2.rejected, (state, action) => {
        state.status.featuredBrands = 'error';
      })
      .addCase(getFeaturedBrandsRequestV2.fulfilled, (state, { payload }) => {
        state.featuredBrandsV2 = payload.data;
        state.status.featuredBrandsV2 = 'succeeded';
      })
      .addCase(getBrandsLettersRequest.pending, (state, action) => {
        state.status.brandsLetters = 'loading';
      })
      .addCase(getBrandsLettersRequest.rejected, (state, action) => {
        state.status.brandsLetters = 'error';
      })
      .addCase(getBrandsLettersRequest.fulfilled, (state, { payload }) => {
        state.brandsLetters = payload.data;
        state.brandsFiltered = Object.keys(payload.data || {}).reduce(
          (accu, key) => {
            payload.data[key].forEach((brand) => {
              const { _id: id_manufacturer } = brand;

              accu.push(Object.assign({ id_manufacturer }, brand));
            });

            return accu;
          },
          [],
        );
        state.status.brandsLetters = 'succeeded';
      })
      .addCase(getBrandRequest.pending, (state, action) => {
        state.status.detailInfo = 'loading';
      })
      .addCase(getBrandRequest.rejected, (state, action) => {
        state.status.detailInfo = 'error';
      })
      .addCase(getBrandRequest.fulfilled, (state, { payload }) => {
        state.status.detailInfo = 'succeeded';
        state.detailInfo = payload.data;
      })
      .addCase(getTotalCategoriesRequest.pending, (state, action) => {
        state.status.totalCategories = 'loading';
      })
      .addCase(getTotalCategoriesRequest.rejected, (state, action) => {
        state.status.totalCategories = 'error';
      })
      .addCase(getTotalCategoriesRequest.fulfilled, (state, { payload }) => {
        state.status.totalCategories = 'succeeded';
        state.totalCategories = payload.data.length;
      })
      .addCase(getTotalProductsRequest.pending, (state, action) => {
        state.status.totalProducts = 'loading';
      })
      .addCase(getTotalProductsRequest.rejected, (state, action) => {
        state.status.totalProducts = 'error';
      })
      .addCase(getTotalProductsRequest.fulfilled, (state, { payload }) => {
        state.status.totalProducts = 'succeeded';
        state.totalProducts = payload.data;
      })
      .addCase(getVouchersRequest.pending, (state, action) => {
        state.status.vouchers = 'loading';
      })
      .addCase(getVouchersRequest.rejected, (state, action) => {
        state.status.vouchers = 'error';
      })
      .addCase(getVouchersRequest.fulfilled, (state, { payload }) => {
        state.status.vouchers = 'succeeded';
        state.vouchers = payload.data;
      })
      .addCase(getBannersRequest.pending, (state, action) => {
        state.status.banners = 'loading';
      })
      .addCase(getBannersRequest.rejected, (state, action) => {
        state.status.banners = 'error';
      })
      .addCase(getBannersRequest.fulfilled, (state, { payload }) => {
        state.status.banners = 'succeeded';
        state.banners = payload.data.length ? payload.data[0] : [];
      })
      .addCase(getAllProductsRequest.pending, (state, action) => {
        state.status.allProducts = 'loading';
      })
      .addCase(getAllProductsRequest.rejected, (state, action) => {
        state.status.allProducts = 'error';
      })
      .addCase(getAllProductsRequest.fulfilled, (state, { payload }) => {
        state.status.allProducts = 'succeeded';
        state.allProducts = payload.data;
      })
      .addCase(getBestSellerProductsRequest.pending, (state, action) => {
        state.status.bestSellerProducts = 'loading';
      })
      .addCase(getBestSellerProductsRequest.rejected, (state, action) => {
        state.status.bestSellerProducts = 'error';
      })
      .addCase(getBestSellerProductsRequest.fulfilled, (state, { payload }) => {
        state.bestSellerProducts = payload.data;
        if (!payload.data.length) {
          state.tabViewName = 'whats_new';
        }
        state.status.bestSellerProducts = 'succeeded';
      })
      .addCase(getWhatsNewProductsRequest.pending, (state, action) => {
        state.status.whatsNewProducts = 'loading';
      })
      .addCase(getWhatsNewProductsRequest.rejected, (state, action) => {
        state.status.whatsNewProducts = 'error';
      })
      .addCase(getWhatsNewProductsRequest.fulfilled, (state, { payload }) => {
        state.status.whatsNewProducts = 'succeeded';
        state.whatsNewProducts = payload.data;
      })
      .addCase(loadMoreAllProductsRequest.pending, (state, action) => {
        state.status.loadMoreAllProducts = 'loading';
      })
      .addCase(loadMoreAllProductsRequest.rejected, (state, action) => {
        state.status.loadMoreAllProducts = 'error';
      })
      .addCase(loadMoreAllProductsRequest.fulfilled, (state, { payload }) => {
        state.status.loadMoreAllProducts = 'succeeded';
        if (!isWeb) {
          const existingIds = state.allProducts.map((product) => product.id);
          const newUniqueProducts = payload.data.filter(
            (product) => !existingIds.includes(product.id),
          );
          state.allProducts = [...state.allProducts, ...newUniqueProducts];
        } else {
          state.allProducts = payload.data;
        }
      })
      .addCase(getBrandCategories.pending, (state, action) => {
        state.status.brandCategories = 'loading';
      })
      .addCase(getBrandCategories.rejected, (state, action) => {
        state.status.brandCategories = 'failed';
      })
      .addCase(getBrandCategories.fulfilled, (state, { payload }) => {
        state.brandCategories = payload.data;
        state.status.brandCategories = 'succeeded';
      })
      .addCase(getBrandProductPromotion.pending, (state, action) => {
        state.status.promotionSection = 'loading';
      })
      .addCase(getBrandProductPromotion.rejected, (state, action) => {
        state.status.promotionSection = 'failed';
      })
      .addCase(getBrandProductPromotion.fulfilled, (state, { payload }) => {
        state.promotionSection = payload.data;
        state.status.promotionSection = 'succeeded';
      });
  },
});

export const { SET_BRAND_STATE, SET_STATUS_BRAND_API } = brandSlice.actions;
export default brandSlice.reducer;

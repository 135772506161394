import { createSlice } from '@reduxjs/toolkit';
import {
  getCategories,
  getCategoriesChildren,
  getAllProductsRequest,
  getTotalProductsRequest,
  loadMoreAllProductsRequest,
  getBannersPromotion,
  getCategoryDetail,
} from '~store/categories/thunks';

// import { storeAsyncData } from '~global/helpers';
import levels from '~data/levels';
import { isWeb } from '~global/screens';

const params = {
  filter: { name: 'Shop By Departments', depth: 3 },
  fields:
    'name my_sociolla_sql_id link_rewrite parents slug banner_for_sociolla lulla_logo',
  skip: 0,
  limit: 30,
  sort: 'position',
};
export const { actions, reducer } = createSlice({
  name: 'categories',
  initialState: {
    home: [],
    list: [],
    subcategories: {},
    childSubcategories: {},
    grandChildSubcategories: {},
    allProducts: {},
    totalProducts: {},
    categoryInfo: {},
    bannersPromotion: {},
    levelIndex: 0,
    isFilterSticky: false,
    heightBannerComponent: 0,
    heightPromoComponent: 0,
    heightSubcategoriesComponent: 0,
    levels,
    params,
    status: {
      allProducts: 'idle',
      home: 'idle',
      list: 'idle',
      loadMoreAllProducts: 'idle',
      totalProducts: 'idle',
      categoryInfo: 'idle',
      bannersPromotion: 'idle',
    },
    categoryId: '',
    isShowMenuCategories: false,
    selectedSubCategoryId: 0,
    selectedCategoryKey: 0,
  },
  reducers: {
    // plural
    setLevels(state, { payload }) {
      const { level_name, tagIndex, tags } =
        payload && Array.isArray(payload)
          ? payload.find(({ tagIndex }) => tagIndex > -1) || payload[0]
          : { level_name: '', tagIndex: '', tags: '' };

      state.levelIndex = payload.findIndex(
        (item) => item.level_name == level_name,
      );
      state.levels = payload;
      state.params.filter =
        tagIndex > -1
          ? { 'phase_tags.id': tags[tagIndex]._id }
          : { name: 'Shop By Departments', depth: 3 };
    },
    // singular
    setLevel(state, { payload: [levelIndex, tagIndex] }) {
      const isShopForAll =
        tagIndex === -1 &&
        state.levels[levelIndex].title === 'All' &&
        state.levels[levelIndex].tags.length > 0;
      state.levelIndex = levelIndex;
      state.levels = state.levels.map((item, index) =>
        index == levelIndex ? { ...item, tagIndex } : { ...item, tagIndex: -1 },
      );
      // storeAsyncData('@storage_levels', state.levels);
      if (tagIndex > -1 || isShopForAll) {
        state.params.filter = isShopForAll
          ? { 'phase_tags.id': state.levels[levelIndex].tags[0]._id }
          : { 'phase_tags.id': state.levels[levelIndex].tags[tagIndex]._id };
      } else {
        state.params.filter = { name: 'Shop By Departments', depth: 3 };
      }
    },
    setParams: (state, { payload: [key, value] }) => {
      state.params[key] = value;
    },
    resetProduct(state, { id }) {
      state.allProducts[id] = [];
      state.totalProducts[id] = '';
      state.status.allProducts = 'idle';
      state.status.totalProducts = 'idle';
    },
    setState(state, { value, name }) {
      state[name] = value;
    },
    setStatusApi(state, { value, name }) {
      state.status[name] = value;
    },
    setCategoryInfo(state, action) {
      state.categoryInfo = action.data;
    },
    showMenuCategories(state) {
      state.isShowMenuCategories = true;
    },
    hideMenuCategories(state) {
      state.isShowMenuCategories = false;
    },
    resetCategoryDetailState(state) {
      state.status.allProducts = 'idle';
      state.status.totalProducts = 'idle';
      state.status.categoryInfo = 'idle';
      state.status.bannersPromotion = 'idle';
      state.allProducts = {};
      state.totalProducts = {};
      state.categoryInfo = {};
      state.bannersPromotion = {};
      state.isFilterSticky = false;
      state.heightBannerComponent = 0;
      state.heightPromoComponent = 0;
      state.heightSubcategoriesComponent = 0;
    },
    setSelectedSubCategoryId(state, { value }) {
      state.selectedSubCategoryId = value;
    },
    setSelectedCategoryKey(state, { value }) {
      state.selectedCategoryKey = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.status.home = 'loading';
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.home = payload;
        state.status.home = 'finished';
      })
      .addCase(getCategoriesChildren.pending, (state) => {
        state.status.list = 'loading';
      })
      .addCase(getCategoriesChildren.rejected, (state) => {
        state.status.list = 'failed';
      })
      .addCase(getCategoriesChildren.fulfilled, (state, { payload }) => {
        const {
          categories,
          subcategories,
          childSubcategories,
          grandChildSubcategories,
        } = payload.data;
        state.list = categories;
        state.subcategories = subcategories;
        state.childSubcategories = childSubcategories;
        state.grandChildSubcategories = grandChildSubcategories;
        state.status.list = 'finished';
      })
      .addCase(getAllProductsRequest.pending, (state) => {
        state.status.allProducts = 'loading';
      })
      .addCase(getAllProductsRequest.rejected, (state) => {
        state.status.allProducts = 'failed';
      })
      .addCase(getAllProductsRequest.fulfilled, (state, { payload }) => {
        state.allProducts[payload.slug] = payload.data;
        state.status.allProducts = 'succeeded';
      })
      .addCase(getTotalProductsRequest.pending, (state) => {
        state.status.totalProducts = 'loading';
      })
      .addCase(getTotalProductsRequest.rejected, (state) => {
        state.status.totalProducts = 'failed';
      })
      .addCase(getTotalProductsRequest.fulfilled, (state, { payload }) => {
        state.totalProducts[payload.slug] = payload.data;
        state.status.totalProducts = 'succeeded';
      })
      .addCase(loadMoreAllProductsRequest.pending, (state) => {
        state.status.loadMoreAllProducts = 'loading';
      })
      .addCase(loadMoreAllProductsRequest.rejected, (state) => {
        state.status.loadMoreAllProducts = 'failed';
      })
      .addCase(loadMoreAllProductsRequest.fulfilled, (state, { payload }) => {
        if (!isWeb) {
          if (state.allProducts[payload.slug]) {
            const existingIds = state.allProducts[payload.slug].map(
              (product) => product.id,
            );
            const uniqueNewProducts = payload.data.filter(
              (product) => !existingIds.includes(product.id),
            );

            state.allProducts[payload.slug] = [
              ...state.allProducts[payload.slug],
              ...uniqueNewProducts,
            ];
          } else {
            state.allProducts[payload.slug] = payload.data;
          }
        } else {
          state.allProducts[payload.slug] = payload.data;
        }

        state.status.loadMoreAllProducts = 'succeeded';
      })
      .addCase(getCategoryDetail.pending, (state) => {
        state.status.categoryInfo = 'loading';
      })
      .addCase(getCategoryDetail.rejected, (state) => {
        state.status.categoryInfo = 'failed';
      })
      .addCase(getCategoryDetail.fulfilled, (state, { payload }) => {
        state.categoryInfo[payload.slug] = payload.data;
        state.categoryId = payload.slug;
        state.status.categoryInfo = 'succeeded';
      })
      .addCase(getBannersPromotion.pending, (state) => {
        state.status.bannersPromotion = 'loading';
      })
      .addCase(getBannersPromotion.rejected, (state) => {
        state.status.bannersPromotion = 'failed';
      })
      .addCase(getBannersPromotion.fulfilled, (state, { payload }) => {
        state.bannersPromotion[payload.slug] = payload.data.sections;
        state.status.bannersPromotion = 'succeeded';
      });
  },
});

export const { setLevels, setLevel, setParams } = actions;

export default reducer;

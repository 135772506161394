/*
Author: Nishant Singh
Description: Every reducer name should be name followed by suffix Reducer with camelCase.
*/

import {
  AUTHENTICATE,
  AUTHENTICATESTATUS,
  SET_PROFILE,
  SET_USER_DATA,
  SET_FRESH_LOGIN,
  SET_TOUR_STEP,
  SET_COMPLETED_PROFILE,
  SET_VOUCHER_COMPLETED_PROFILE,
  SET_FORGOT_PASSWORD,
  SET_QUICK_TOUR_HOME,
  SET_USER_ADDRESS,
} from '../actions/auth';

// ........States
const initialState = {
  isLoggedIn: false,
  token: null,
  userAddress: [],
  user: {},
  userId: null,
  profile: {
    children: [],
  },
  isFreshLogin: false,
  step: { type: 1, max: null, message: '', info: '' },
  firebaseToken: null,
  mobileTokenLilla: null,
  progressProfile: '',
  voucherCompletedProfile: '',
  forgotPassword: {},
  quickTourHome: 'idle',
  autoRedirect: {},
  authStatus: 'idle',
  isResetOtp: false,
  isResetOtpParent: false,
  parentEmail: '',
};

// ........Reducers
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      // eslint-disable-next-line no-case-declarations
      const data = action.data;
      return {
        ...state,
        isLoggedIn: data.isLoggedIn
          ? data.isLoggedIn
          : data.token
          ? true
          : Boolean(data.user?._id),
        ...data,
      };
    case AUTHENTICATESTATUS:
      return { ...state, authStatus: action.data };
    case SET_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.data } };
    case SET_USER_DATA:
      return { ...state, user: { ...state.user, ...action.data } };
    case SET_USER_ADDRESS:
      return {
        ...state,
        userAddress: { ...state.userAddress, ...action.data },
      };
    case SET_FRESH_LOGIN:
      return { ...state, isFreshLogin: action.data };
    case SET_TOUR_STEP:
      return { ...state, step: action.data };
    case SET_COMPLETED_PROFILE:
      return { ...state, progressProfile: action.data };
    case SET_VOUCHER_COMPLETED_PROFILE:
      return { ...state, voucherCompletedProfile: action.data };
    case 'SET_FIREBASE_TOKEN':
      return { ...state, firebaseToken: action.data };
    case 'SET_MOBILE_TOKEN_LILLA':
      return { ...state, mobileTokenLilla: action.data };
    case 'SET_VERIFIED_PHONE':
      return { ...state, user: { ...state.user, is_verified_phone: true } };
    case 'SET_RESET_OTP':
      return { ...state, isResetOtp: action.data };
    case SET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, ...action.data },
      };
    case SET_QUICK_TOUR_HOME:
      return { ...state, quickTourHome: action.data };
    case 'SET_AUTO_REDIRECT':
      return { ...state, autoRedirect: action.data };
    case 'SET_RESET_OTP_PARENT':
      return { ...state, isResetOtpParent: action.data };
    case 'SET_PARENT_EMAIL':
      return { ...state, parentEmail: action.data };
    default:
      return state;
  }
};

export default authReducer;
